<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-alert
          border="top"
          dense
          outlined
          color="red lighten-2"
          type="error"
          dismissible
          v-model="alert.error"
        >
          {{ alert.message }}
        </v-alert>
        <v-alert
          border="top"
          dense
          outlined
          color="green lighten-2"
          type="success"
          dismissible
          v-model="alert.success"
        >
          {{ alert.message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AlertComponent",

  props: ["alert"],

  data: () => ({}),
  
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {},
});
</script>