<template>
  <v-container v-if="userLoggedIn">
    <v-row v-if="alert.error || alert.success">
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="10">
        Partywise Sales Report
      </v-col>
      <!-- add a button to download the report as CSV -->
      <v-col cols="2">
        <v-btn @click="downloadReport" color="success" rounded>Download CSV</v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12" class="no-padding">
        <v-simple-table ref="party_wise_report_table" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td colspan="2"><b>Party</b></td>
                <td colspan="6"><b>Bills</b></td>
              </tr>
              <tr>
                <td colspan="2">
                  <v-autocomplete :items="partyNameList" v-model="customer" label="Customer" @change="partyChanged"
                    required class="no-padding">
                  </v-autocomplete>
                </td>
                <td colspan="6">
                  <v-menu ref="dateRangeMenu" v-model="dateRangeMenu" :close-on-content-click="false"
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateRange" label="Date Range" persistent-hint
                        :prepend-icon="icons.mdiCalendar" v-bind="attrs" v-on="on" readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" no-title @input="dateRangeMenu = false" range></v-date-picker>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td>Date</td>
                <td>Bill No.</td>
                <td>Name</td>
                <td>City</td>
                <td>Taxable Amount</td>
                <td>( Discount )</td>
                <td>Tax</td>
                <td>Total Amount</td>
              </tr>
            </thead>
            <tbody>
              <template v-for="party_record in filteredParty">
                <template v-for="sales_record in party_record">
                  <tr :key="sales_record.id">
                    <td>{{ (new Date(sales_record.createdAt)).toLocaleDateString() }}</td>
                    <td>{{ sales_record.order_id }}</td>
                    <td>{{ sales_record.party_name }}</td>
                    <td>{{ sales_record.party_city }}</td>
                    <td>{{ (sales_record.order_value - sales_record.total_tax -
                      sales_record.total_discount).toFixed(2) }}</td>
                    <td>{{ sales_record.total_discount.toFixed(2) }}</td>
                    <td>{{ sales_record.total_tax.toFixed(2) }}</td>
                    <td>{{ sales_record.order_value.toFixed(2) }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import { mdiCalendar } from "@mdi/js";

import { partyWiseSalesReport } from "../../services/apiCall.js";
import AlertComponent from "../common/AlertComponent";

export default Vue.extend({
  name: "PartySalesReport",

  components: {
    AlertComponent,
  },

  data: () => ({
    icons: {
      mdiCalendar
    },
    alert: {
      error: false,
      success: false,
      message: "",
    },
    partySalesSummary: {},
    customer: "",
    partyNameList: [],
    dateRange: [],
    dateRangeMenu: false,
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    filteredParty() {
      if (this.customer == null || this.customer == "") {
        return this.partySalesSummary;
      } else {
        // filter party names starting with this.customer
        let partyIds = Object.keys(this.partySalesSummary);
        let filteredPartyList = {};
        partyIds.forEach((partyId) => {
          let party = this.partySalesSummary[partyId];
          if (party[0].party_name.startsWith(this.customer)) {
            filteredPartyList[partyId] = party;
          }
        });

        if (this.dateRange.length == 2) {
          // then filter by date range
          let filteredPartyListByDate = {};
          Object.keys(filteredPartyList).forEach((partyId) => {
            let party = filteredPartyList[partyId];
            let filteredParty = party.filter((sales_record) => {
              let date = new Date(sales_record.createdAt);
              return (
                date >= new Date(this.dateRange[0]) &&
                date <= new Date(this.dateRange[1])
              );
            });
            filteredPartyListByDate[partyId] = filteredParty;
          });

          return filteredPartyListByDate;
        } else {
          return filteredPartyList;
        }
      }
    }
  },

  methods: {
    init() {
      console.log('PartySalesReport.init');

      this.dateRange = [];

      this.fetchReport(new Date());
    },

    fetchReport(reportDate) {
      partyWiseSalesReport({ report_date: reportDate })
        .then((res) => {
          if (res.data.status == 0) {
            this.partySalesSummary = res.data.result.party_wise_sales;
            this.partyIds = Object.keys(this.partySalesSummary);
            this.partyNameList = this.partyIds.map((pid) => {
              let party = this.partySalesSummary[pid];
              return party[0].party_name;
            });
          }
        })
        .catch((err) => {
          console.log("dailySalesReport - err", err);
          this.alert.error = true;
          this.alert.success = false;
          this.alert.message = "Unable to connect to server. Error: " + err;
        });
    },

    partyChanged() {
      console.log(
        "partyChanged",
        this.customer
      );
    },

    formatString(str) {
      return str
        .trim()          // Remove leading and trailing spaces
        .toLowerCase()   // Convert to lower case
        .replace(/\s+/g, '-');  // Replace spaces with dashes
    },

    downloadReport() {
      const table = this.$refs.party_wise_report_table.$el;
      const rows = Array.from(table.querySelectorAll('tr'));
      const csvContent = rows.map(row => {
        const cols = Array.from(row.querySelectorAll('th, td'));
        return cols.map(col => col.innerText).join(',');
      }).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.formatString(this.customer) + '-report.csv';
      a.click();
      URL.revokeObjectURL(url);
    }
  },
});
</script>