<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>Add Item</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="itemForm" v-model="valid" lazy-validation>
          <v-row class="text-left">
            <v-col>
              <v-autocomplete :items="categories" v-model="item.category" :rules="[rules.required]" label="Category"
                required>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field v-model="item.storage.shelf" :rules="[rules.positiveNumber]" label="Shelf"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="item.storage.rack" :rules="[rules.positiveNumber]" label="Rack"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="isLensCategory()">
            <v-col>
              <v-text-field v-model="item.attributes.size" :rules="[rules.required, rules.positiveNumber]" type="number"
                label="Size" required></v-text-field>
            </v-col>
            <v-col>
              <v-combobox v-model="item.attributes.colour" :rules="[rules.required]" @input="(v) => {
                  item.attributes.colour = v.toUpperCase();
                }
                " label="Colour" :items="attributeList.colour" :search-input.sync="attributeList.searchColour" @change="
    attributeList.colour.push(
      item.attributes.colour.toUpperCase()
    )
    " hide-selected persistent-hint single required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          attributeList.search
                        }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <v-combobox v-model="item.attributes.type" :rules="[rules.required]" @input="(v) => {
                  item.attributes.type = v.toUpperCase();
                }
                " label="Type" :items="attributeList.type" :search-input.sync="attributeList.searchType" @change="
    attributeList.type.push(item.attributes.type.toUpperCase())
    " hide-selected persistent-hint single required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          attributeList.search
                        }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <v-combobox v-model="item.attributes.product" :rules="[rules.required]" @input="(v) => {
                  item.attributes.product = v.toUpperCase();
                }
                " label="Product" :items="attributeList.product" :search-input.sync="attributeList.searchProduct"
                @change="
                  attributeList.product.push(
                    item.attributes.product.toUpperCase()
                  )
                  " hide-selected persistent-hint single required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          attributeList.search
                        }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <v-combobox v-model="item.attributes.company" :rules="[rules.required]" @input="(v) => {
                  item.attributes.company = v.toUpperCase();
                }
                " label="Company" :items="attributeList.company" :search-input.sync="attributeList.searchCompany"
                @change="
                  attributeList.company.push(
                    item.attributes.company.toUpperCase()
                  )
                  " hide-selected persistent-hint single required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          attributeList.search
                        }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <v-text-field v-model="item.attributes.sph" :rules="[rules.required, rules.validPowers, rules.validSph]"
                label="SPH" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="item.attributes.cyl" :rules="[rules.required, rules.validPowers, rules.validCyl]"
                label="CYL" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="item.attributes.add" :rules="[rules.required, rules.validPowers, rules.validPowers]"
                label="ADD" required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="item.attributes.degree" :rules="[rules.required, rules.validDegree, rules.noFloat]"
                label="Degree" type="number" required></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete v-model="item.attributes.rl" label="R/L" type="text" value="-"
                :items="['R', 'L', ' ']"></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="text-left">
            <v-col>
              <v-input :disabled="true" v-if="isLensCategory()">Item Name
                <b>
                  <pre style="color: black">&nbsp;{{ getItemName() }}</pre>
                </b></v-input>
              <v-text-field v-if="!isLensCategory()" v-model="item.item_name" label="Item Name" @input="(v) => {
                  item.item_name = v.toUpperCase();
                }
                ">
              </v-text-field>

              <v-row>
                <v-col>
                  <v-text-field v-model="item.bar_code" label="Barcode"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.short_code" label="Short Code" @input="(v) => {
                      item.short_code = v.toUpperCase();
                    }
                    "></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field v-model="item.counter_price" label="Counter Rate" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.dispatch_price" label="Dispatch Rate" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.tax.sgst" label="SGST (%)" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.tax.cgst" label="CGST (%)" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.tax.igst" label="IGST (%)" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.tax.cess" label="Cess (%)" type="number"
                    :rules="[rules.required, rules.positiveNumber]" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="item.tax.hsn_code" label="HSN" :rules="[rules.required]" required></v-text-field>
                </v-col>
                <v-col v-if="isCourierCategory()">
                  <v-switch v-model="item.tax.composite_supply" label="Composite Supply?" inset></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-input disabled>Tax Inclusive Rates:
                    <b>
                      <pre style="color: black">
&nbsp; {{ getCounterRate() }} (<i>counter</i>)</pre>
                    </b>
                    <b>
                      <pre style="color: black">
&nbsp; {{ getDispatchRate() }} (<i>dispatch</i>)</pre>
                    </b>
                  </v-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="text-left">
            <v-col>
              <v-text-field v-model="item.stock.quantity" :rules="[rules.required, rules.positiveNumber]"
                label="Opening Stock" required>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="item.stock.min_quantity" :rules="[rules.required, rules.positiveNumber]"
                label="Min Order Quantity" required>
              </v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete v-model="item.stock.units" label="Units" :items="['PAIRS', 'PIECE']" @input="(v) => {
                  item.stock.units = v.toUpperCase();
                }
                ">
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="text-left">
            <v-col>
              <v-text-field v-model="item.remark" label="Remark">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col offset="8" cols="4" md="4">
              <v-btn :disabled="!valid || submitDisabled" color="success" @click="createTheItem">
                Create Item
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import {
  createItem,
  categories,
  types,
  companies,
  products,
  colours,
} from "../../services/apiCall.js";

import AlertComponent from "../common/AlertComponent.vue";

export default Vue.extend({
  components: { AlertComponent },
  name: "AddItemComponent",

  created: function () {
    try {
      categories({})
        .then((res) => {
          // console.log("categories", res, this);
          if (res.data.status === 0) {
            this.saveCategories(res);
          }
        })
        .catch((err) => {
          console.log("categories - err", err);
        });
    } catch (err) {
      console.log("categories - err", err);
    }

    try {
      types({})
        .then((res) => {
          // console.log("types", res, this);
          if (res.data.status === 0) {
            this.saveTypes(res);
          }
        })
        .catch((err) => {
          console.log("types - err", err);
        });
    } catch (err) {
      console.log("types - err", err);
    }

    try {
      colours({})
        .then((res) => {
          // console.log("colors", res, this);
          if (res.data.status === 0) {
            this.saveColours(res);
          }
        })
        .catch((err) => {
          console.log("colours - err", err);
        });
    } catch (err) {
      console.log("colours - err", err);
    }

    try {
      companies({})
        .then((res) => {
          // console.log("companies", res, this);
          if (res.data.status === 0) {
            this.saveCompanies(res);
          }
        })
        .catch((err) => {
          console.log("companies - err", err);
        });
    } catch (err) {
      console.log("companies - err", err);
    }

    try {
      products({})
        .then((res) => {
          // console.log("products", res, this);
          if (res.data.status === 0) {
            this.saveProducts(res);
          }
        })
        .catch((err) => {
          console.log("products - err", err);
        });
    } catch (err) {
      console.log("products - err", err);
    }
  },
  data: () => ({
    item: {
      item_name: "",
      bar_code: "",
      short_code: "",
      attributes: {
        size: "",
        colour: "",
        type: "",
        product: "",
        company: "",
        sph: "0.0",
        cyl: "0.0",
        add: "0.0",
        degree: "0",
        rl: " ",
      },
      stock: {
        quantity: 0.0,
        min_quantity: 0.0,
        units: "PAIRS",
      },
      dispatch_price: 0.0,
      counter_price: 0.0,
      tax: {
        sgst: 6.0,
        cgst: 6.0,
        igst: 12.0,
        cess: 0.0,
        hsn_code: "9004",
        composite_supply: false, // this flag is used to determine if the GST rate of this item is to be paired with principle item in the bill
      },
      storage: {
        shelf: 0,
        rack: 0,
      },
      category: "Lens",
    },
    attributeList: {
      colour: [],
      company: [],
      type: [],
      product: [],
      searchColour: "",
      searchCompany: "",
      searchType: "",
      searchProduct: "",
    },
    alert: {
      error: false,
      success: false,
      message: "",
    },
    submitDisabled: false,
    valid: false,
    categories: [],
    rules: {
      required: (v) => !!v || "Required",
      positiveNumber: (v) => parseFloat(v) >= 0 || "Should not be -ve number",
      validPowers: (v) =>
        /^[+-\\]\d*(\.00|\.25|\.50|\.75|\.0|\.5)?$/.test(v) || "Invalid Power",
      validSph: (v) =>
        (parseFloat(v) >= -35 && parseFloat(v) <= 35) || "Out of range",
      validCyl: (v) =>
        (parseFloat(v) >= -15 && parseFloat(v) <= 15) || "Out of range",
      validAdd: (v) =>
        (parseFloat(v) >= -10 && parseFloat(v) <= 10) || "Out of range",
      validDegree: (v) => (v >= 0 && v <= 360) || "Invalid Degree",
      noFloat: (v) => v.indexOf(".") < 0 || "No partial value",
    },
  }),
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    isLensCategory() {
      return this.item.category === "Lens";
    },
    isCourierCategory() {
      return this.item.category === "Courier";
    },
    saveCategories(res) {
      var categoryList = res.data.result.category_list;
      this.$store.dispatch("setCategories", categoryList);
      this.categories = categoryList.map((x) => x.category_name);
    },
    saveTypes(res) {
      this.attributeList.type = res.data.result.type_list;
    },
    saveCompanies(res) {
      this.attributeList.company = res.data.result.company_list;
    },
    saveColours(res) {
      this.attributeList.colour = res.data.result.colour_list;
    },
    saveProducts(res) {
      this.attributeList.product = res.data.result.product_list;
    },
    createTheItem() {
      this.item.item_name = this.isLensCategory()
        ? this.getItemName()
        : this.item.item_name;

      console.log("createTheItem", this.item.item_name);

      if (this.$refs.itemForm.validate()) {
        this.submitDisabled = true;
        try {
          var newItem = {
            item_name: this.item.item_name,
            attributes: this.item.attributes,
            counter_price: this.item.counter_price,
            dispatch_price: this.item.dispatch_price,
            bar_code: this.item.bar_code,
            short_code: this.item.short_code,
            hsn_code: this.item.tax.hsn_code,           
            tax_inclusive_rate: false, // TODO: this field needs to be handled in future
            tax_rate: this.item.tax,
            remark: this.item.remark,
            stock: this.item.stock,
            category: this.item.category,
            storage: this.item.storage,
          };
          console.log("The New Item", newItem);
          createItem(newItem)
            .then((res) => {
              if (res.data.status === 0) {
                console.log("createItem - new", res);
                this.alert.success = true;
                this.alert.message = "New Item Added";
              } else {
                console.log("createItem - err 1", res);
                this.submitDisabled = false;
                this.alert.error = true;
                this.alert.message = "Error creating item: " + res.data.error.error_message;
              }
            })
            .catch((err) => {
              console.log("createItem - err 2", err);
              this.submitDisabled = false;
              this.alert.message = "Error creating item: " + JSON.stringify(err);
            });
        } catch (err) {
          console.log("createItem - err 3", err);
          this.submitDisabled = false;
          this.submitDisabled = false;
          this.alert.message = "Error creating item: " + JSON.stringify(err);
        }
      }
    },
    sanitizeString(str) {
      return str.replace(" ", "").toUpperCase();
    },
    getItemName() {
      return (
        this.item.attributes.size +
        " " +
        this.sanitizeString(this.item.attributes.colour) +
        " " +
        this.sanitizeString(this.item.attributes.type) +
        " " +
        this.sanitizeString(this.item.attributes.product) +
        " " +
        this.sanitizeString(this.item.attributes.company) +
        " " +
        this.item.attributes.sph +
        "/S" +
        " " +
        this.item.attributes.cyl +
        "/C" +
        " " +
        this.item.attributes.add +
        "/A" +
        " " +
        this.item.attributes.degree +
        "/D" +
        (this.item.attributes.rl === " " ? "" : " " + this.item.attributes.rl)
      );
    },
    computeTaxRate(rate) {
      var sgst = (rate * this.item.tax.sgst) / 100.0;
      var cgst = (rate * this.item.tax.cgst) / 100.0;
      var cess = (rate * this.item.tax.cess) / 100.0;

      return sgst + cgst + cess;
    },
    getDispatchRate() {
      var rate = parseFloat(this.item.dispatch_price);
      var taxRate = this.computeTaxRate(rate);
      return rate + taxRate;
    },
    getCounterRate() {
      var rate = parseFloat(this.item.counter_price);
      var taxRate = this.computeTaxRate(rate);
      return rate + taxRate;
    },
  },
});
</script>