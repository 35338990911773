<template>
    <v-container v-if="userLoggedIn">
        <v-row v-if="alert.error || alert.success" class="no-padding">
            <v-col cols="12" class="no-padding">
                <alert-component :alert="alert"></alert-component>
            </v-col>
        </v-row>

        <v-row class="text-right" dense>
            <v-col cols="12" dense>
                <v-btn v-if="buttonPressed.addParty" depressed @click="addParty" color="error" rounded>Close</v-btn>
                <v-btn v-if="!buttonPressed.addParty" raised @click="addParty" color="success" rounded>Add / Update
                    Party</v-btn>
            </v-col>
        </v-row>

        <v-row class="text-center no-padding" dense>
            <v-col cols="12" class="no-padding" dense>
                <add-party-component v-if="buttonPressed.addParty"></add-party-component>
                <v-data-table dense :headers="headers" :items="externalPartyList" :items-per-page="50" class="elevation-1"
                    clearable>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from "vue";

import AlertComponent from "../common/AlertComponent.vue";
import AddPartyComponent from "../billing/AddPartyComponent.vue";

import {
    externalParties
} from "../../services/apiCall.js";

export default Vue.extend({
    components: { AlertComponent, AddPartyComponent },
    name: "PartyListComponent",

    mounted() {
        this.init();
    },

    data: () => ({
        buttonPressed: {
            addParty: false,
        },
        alert: {
            error: false,
            success: false,
            message: "",
        },
        headers: [
            { text: "Sr. No.", value: "idx" },
            { text: "Name", value: "party_name" },
            { text: "Address", value: "party_address" },
            { text: "Contact", value: "party_contact_no" },
            { text: "GSTIN", value: "party_gstin" },
        ],
        externalPartyList: [],
    }),

    computed: {
        userLoggedIn() {
            return this.$store.getters.userLoggedIn;
        },
    },

    methods: {
        init() {
            externalParties({ party_type: "Customer" })
                .then((res) => {
                    console.log("externalParties", res);

                    if (res.data.status === 0) {
                        this.externalPartyList = res.data.result.external_party_list;
                        let idx = 1;
                        this.externalPartyList.map((x) => x["idx"] = idx++);
                    }
                });
        },
        addParty() {
            console.log("addParty");
            this.resentButtonPressed("addParty");
        },
        resentButtonPressed(theKey) {
            for (var key in this.buttonPressed) {
                if (key == theKey) {
                    this.buttonPressed[key] = !this.buttonPressed[key];
                } else {
                    this.buttonPressed[key] = false;
                }
            }
        }
    }
})
</script>
