<template>
  <v-container v-if="userLoggedIn">
    <v-row v-if="alert.error || alert.success">
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12"> Daily Item Stock Report </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12" class="no-padding">
        <table width="100%" style="border: 0px; border-spacing: 0px">
        
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "DailyItemStockReport",

  data: () => ({
    alert: {
      error: false,
      success: false,
      message: "",
    },
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
        console.log('DailySalesReport.init');
    }
  },
});
</script>