<template>
  <tr>
    <td
      style="
        border-top: 1px solid;
        border-left: 1px solid;
        border-bottom: 1px solid;
        text-align: left;
        padding-left: 5px;
      "
      colspan="7"
    >
      <small>
        INSTRUCTIONS: <br />
        (a) Interest @ 15% will be charged after 7 days from presentation. (b)
        Subject to Kodungallur Jurisdiction. (c) Our responsibility ceases once
        the goods are delivered / dispatched. <br /><br /><br />
        Receiver's Signature</small
      >
    </td>
    <td
      style="
        border-top: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        text-align: left;
        padding-right: 5px;
        padding-left: 10px;
        vertical-align: top;
      "
      colspan="3"
    >
      <small>
        For SUDARSAN SCIENTIFIC COMPANY <br /><br /><br />
        Partner</small
      >
    </td>
  </tr>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BillFooterComponent",
});
</script>