<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <v-text-field label="Search item by name, barcode or short code" v-model="searchField"
          @keypress.enter="searchTheItem" @keypress="keypressSearchTheItem" hide-details="auto"
          :append-icon="icons.mdiMagnify" @input="(v) => {
            searchField = v.toUpperCase();
          }
            " single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table dense :headers="headers" :items="itemList" :items-per-page="5" class="elevation-1"
          :search="searchField" clearable>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="editItem(item)">{{ icons.mdiFileEdit }}</v-icon>
            <v-icon @click="printBarcodeLabel(item)">{{
              icons.mdiPrinter
            }}</v-icon>
          </template>

          <template v-slot:[`item.bar_code_label`]="{ item }">
            <VueBarcode v-bind:value="item.bar_code" width="2" height="30" font-size="12" format="CODE128">
              NA
            </VueBarcode>
          </template>

          <template v-slot:[`item.counter_price`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'counter_price')" @cancel="cancel"
              @open="open(item, editableProperties, 'counter_price')" @close="close">
              {{ item.counter_price }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.counter_price" type="number" label="Edit"
                  :rules="editablePropertyRules.counter_price" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.dispatch_price`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'dispatch_price')" @cancel="cancel"
              @open="open(item, editableProperties, 'dispatch_price')" @close="close">
              {{ item.dispatch_price }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.dispatch_price" type="number" label="Edit"
                  :rules="editablePropertyRules.dispatch_price" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.discount`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'discount')" @cancel="cancel"
              @open="open(item, editableProperties, 'discount')" @close="close">
              {{ item.discount }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.discount" type="number" label="Edit"
                  :rules="editablePropertyRules.discount" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'quantity')" @cancel="cancel"
              @open="open(item, editableProperties, 'quantity')" @close="close">
              {{ item.quantity }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.quantity" type="number" label="Edit"
                  :rules="editablePropertyRules.quantity" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.bar_code`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'bar_code')" @cancel="cancel"
              @open="open(item, editableProperties, 'bar_code')" @close="close">
              {{ item.bar_code }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.bar_code" label="Edit" :rules="editablePropertyRules.bar_code"
                  single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.short_code`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'short_code')" @cancel="cancel"
              @open="open(item, editableProperties, 'short_code')" @close="close">
              {{ item.short_code }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.short_code" label="Edit"
                  :rules="editablePropertyRules.short_code" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.min_quantity`]="{ item }">
            <v-edit-dialog @save="save(item, editableProperties, 'min_quantity')" @cancel="cancel"
              @open="open(item, editableProperties, 'min_quantity')" @close="close">
              {{ item.min_quantity }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.min_quantity" type="number" label="Edit"
                  :rules="editablePropertyRules.min_quantity" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="showPrintPreview">
      <v-col cols=12>
        <barcode-label-component :barcode="printPreviewItem.barcode" :itemName="printPreviewItem.item_name"
          :itemCode="printPreviewItem.item_code" :paperType="printPreviewItem.paper_type"></barcode-label-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import VueBarcode from 'vue-barcode';

import { mdiFileEdit, mdiPrinter, mdiMagnify } from "@mdi/js";

import { itemSearch, updateItemField } from "../../services/apiCall.js";

import BarcodeLabelComponent from "../common/BarcodeLabelComponent.vue";

export default Vue.extend({
  name: "ItemListComponent",

  mounted() {
    this.init();
  },

  components: {
    VueBarcode,
    BarcodeLabelComponent,
  },

  data: () => ({
    icons: {
      mdiFileEdit,
      mdiPrinter,
      mdiMagnify,
    },
    editablePropertyRules: {
      bar_code: [],
      short_code: [],
      counter_price: [],
      dispatch_price: [],
      discount: [],
      quantity: [],
      min_quantity: [],
    },
    editableProperties: {
      bar_code: "",
      short_code: "",
      counter_price: "",
      dispatch_price: "",
      discount: "",
      quantity: "",
      min_quantity: "",
    },
    fieldTypes: {
      bar_code: "string",
      short_code: "string",
      counter_price: "number",
      dispatch_price: "number",
      discount: "number",
      quantity: "number",
      min_quantity: "number",
    },
    rules: {
      required: (v) => !!v || "Required",
      validQty: (v) => /^\d*(\.00|\.50\.0|\.5)?$/.test(v) || "Invalid Qty",
      positiveNumber: (v) => parseFloat(v) >= 0 || "Should not be -ve number",
      validDiscount: (v) => parseFloat(v) >= 0 && parseFloat(v) <= 100,
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Name", value: "item_name" },
      { text: "Barcode", value: "bar_code" },
      { text: "Short code", value: "short_code" },
      { text: "Counter Rate", value: "counter_price" },
      { text: "Dispatch Rate", value: "dispatch_price" },
      { text: "Qty", value: "quantity" },
      { text: "Min. Qty", value: "min_quantity" },
      { text: "Barcode Label", value: "bar_code_label" },
      { text: "Actions", value: "actions" },
    ],
    itemList: [],
    searchField: "",
    searchActive: false,
    showPrintPreview: false,
    printPreviewItem: {
      barcode: "",
      item_name: "",
      item_code: "",
      paper_type: "A4",
    },
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
      console.log("ItemListComponent.init called");
      console.log("itemList", this.itemList);

      this.editablePropertyRules.counter_price = [
        this.rules.required,
        this.rules.positiveNumber,
      ];
      this.editablePropertyRules.discount = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validDiscount,
      ];
      this.editablePropertyRules.quantity = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validQty,
      ];

      this.editablePropertyRules.dispatch_price = [
        this.rules.required,
        this.rules.positiveNumber,
      ];
      this.editablePropertyRules.discount = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validDiscount,
      ];
      this.editablePropertyRules.quantity = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validQty,
      ];

      console.log("editableProperyRules", this.editablePropertyRules);
    },
    keypressSearchTheItem() {
      if (this.searchActive) return;

      if (this.searchField.trim() === "") return;
      if (this.searchField.length < 2) return;

      if (this.searchActive) return;

      this.searchActive = true;
      setTimeout(this.searchTheItem, 2000);
    },
    validate(prop, key) {
      var isValid = true;
      var rules = this.editablePropertyRules[key];

      for (var ridx = 0; ridx < rules.length; ridx++) {
        var ruleValidity = rules[ridx](prop[key]);
        // console.log('ruleValidity', key, rules[ridx], ruleValidity);
        if (typeof ruleValidity === "string") ruleValidity = false;
        isValid = isValid && ruleValidity;
      }
      // console.log('validate', key, isValid);
      return isValid;
    },
    save(prop, eprop, key) {
      if (this.validate(eprop, key)) {
        prop[key] = eprop[key];
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Updated [" + key + "]";
        let updateItemPayload = {
          item_id: prop.id,
          field_name: key,
          field_value: prop[key],
          field_type: this.fieldTypes[key],
        };
        console.log('updateIemPayload', prop, updateItemPayload);
        updateItemField(updateItemPayload)
          .then((res) => {
            if (res.data.status === 0) {
              console.log("updateItemField - update", res);
            } else {
              console.log("updateItemField - err 1", res);
            }
          })
          .catch((err) => {
            console.log("updateItemField - err 2", err);
          });
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Validaion failed [" + key + "]";
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open(prop, eprop, key) {
      eprop[key] = prop[key];
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Edit opened [" + key + "]";
    },
    close() {
      console.log("Edit closed");
    },
    searchTheItem() {
      console.log("searchItem", this.searchField);
      if (this.searchField === "") {
        this.searchActive = false;
        return;
      }
      try {
        itemSearch({ search_filter: this.searchField })
          .then((res) => {
            console.log("itemSearch", res);
            if (res.data.status === 0) {
              this.itemList = res.data.result.item_list;
              if (this.itemList.length === 1) {
                this.searchField = "";
              }
              this.searchActive = false;
            }
          })
          .catch((err) => {
            console.log("itemSearch - err", err);
            this.itemList = [];
            this.searchActive = false;
          });
      } catch (err) {
        console.log("searchTheItem - err", err);
        this.itemList = [];
        this.searchActive = false;
      }
    },
    editItem(item) {
      console.log("editItem", item);
    },
    printBarcodeLabel(item) {
      console.log("printBarcodeLabel", item);
      this.printPreviewItem = {
        barcode: item.bar_code,
        item_name: item.item_name,
        item_code: item.id,
        paper_type: "A4",
      };
      this.showPrintPreview = !this.showPrintPreview;
    }
  },
});
</script>