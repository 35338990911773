<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>Optical Lens Sheet</h3>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col>
                <v-combobox :items="product_companies" v-model="selected_company" label="Company"></v-combobox>
              </v-col>
              <v-col>
                <v-combobox :items="product_types" v-model="selected_type" label="Product Type"></v-combobox>
              </v-col>
              <v-col>
                <v-combobox :items="product_attributes" v-model="selected_attribute"
                  label="Product Attribute"></v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn @click="fetchProductDetails" rounded>Fetch</v-btn>
          </v-col>
        </v-row>
        <!-- filter based on sphirical and cyleinder ranges -->
        <v-row>
          <v-col>
            <v-text-field v-model="range.spherical.min" label="SPH Min" type="number" required />
          </v-col>
          <v-col>
            <v-text-field v-model="range.spherical.max" label="SPH Max" type="number" required />
          </v-col>
          <v-col>
            <v-text-field v-model="range.cylinder.min" label="CYL Min" type="number" required />
          </v-col>
          <v-col>
            <v-text-field v-model="range.cylinder.max" label="CYL Max" type="number" required />
          </v-col>
          <v-col>
            <v-btn @click="filterSheet" rounded>Filter</v-btn>
          </v-col>
        </v-row>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td></td>
                <template v-for="col_header in headers.cols">
                  <td v-if="col_header.is_visible" v-bind:key="col_header.value">
                    <b>
                      {{ formatRange(col_header.value) }}
                    </b>
                  </td>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="row_header in headers.rows">
                <tr v-if="row_header.is_visible" v-bind:key="row_header.value">
                  <td>
                    <b>{{ formatRange(row_header.value) }}</b>
                  </td>
                  <template v-for="col_header in headers.cols">
                    <td v-bind:key="col_header.value" v-if="col_header.is_visible" class="pa-2 ma-2">
                      <v-text-field :value="fetchValue(col_header.value, row_header.value)" style="font-size: 10px;"
                        class="pa-0 ma-0 text-center" variant="solo" dense></v-text-field>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";

import {
  types,
  companies,
  itemSpecificList,
} from "../../services/apiCall.js";

export default Vue.extend({
  name: "OpticalSheetComponent",

  mounted() {
    this.init();
  },

  data: () => ({
    range: {
      spherical: {
        min: -10.0,
        max: 10.0,
        step: 0.25
      },
      cylinder: {
        min: -10.0,
        max: 10.0,
        step: 0.25
      }
    },

    headers: {
      cols: [],
      rows: []
    },

    product_companies: [],
    product_types: [],
    product_attributes: [
      "Stock Count",
      "Counter Price",
      "Dispatch Price",
      "Short Code",
      "Bar Code",
      "Min. Order. Qty."
    ],
    product_attribute_map: {
      "Stock Count": "quantity",
      "Min. Order. Qty.": "min_quantity",
      "Counter Price": "counter_price",
      "Dispatch Price": "dispatch_price",
      "Short Code": "short_code",
      "Bar Code": "bar_code"
    },
    product_list: [],

    selected_company: "",
    selected_type: "",
    selected_attribute: ""
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {
    init() {
      console.log("OpticalSheetComponent.init called");

      this.headers.cols = [];
      this.headers.rows = [];
      for (let cnt = this.range.spherical.min; cnt <= this.range.spherical.max; cnt += this.range.spherical.step) {
        this.headers.rows.push({
          "value": cnt + "",
          "is_visible": true
        });
      }
      for (let cnt = this.range.cylinder.min; cnt <= this.range.cylinder.max; cnt += this.range.cylinder.step) {
        this.headers.cols.push({
          "value": cnt + "",
          "is_visible": true
        });
      }
      // console.log(this.headers)

      try {
        types({})
          .then((res) => {
            // console.log("types", res, this);
            if (res.data.status === 0) {
              this.product_types = res.data.result.type_list;
            }
          })
          .catch((err) => {
            console.log("types - err", err);
          });
      } catch (err) {
        console.log("types - err", err);
      }

      try {
        companies({})
          .then((res) => {
            // console.log("companies", res, this);
            if (res.data.status === 0) {
              this.product_companies = res.data.result.company_list;
            }
          })
          .catch((err) => {
            console.log("companies - err", err);
          });
      } catch (err) {
        console.log("companies - err", err);
      }
    },

    formatRange(range) {
      return parseFloat(range).toFixed(2).padStart(3, '0').replace('.', '')
    },

    fetchValue(col_header, row_header) {
      let item = this.product_list.filter((x) => {
        let cond = parseFloat(x.attributes.cyl) == parseFloat(col_header) && parseFloat(x.attributes.sph) == parseFloat(row_header);
        return cond
      })

      if (item.length > 0) {
        console.log('fetchValue', col_header, row_header, item[0][this.product_attribute_map[this.selected_attribute]])
        return item[0][this.product_attribute_map[this.selected_attribute]]
      }

      return 0.0
    },

    fetchProductDetails() {
      try {
        itemSpecificList({
          product_type: this.selected_type,
          product_company: this.selected_company,
          product_attribute: this.product_attribute_map[this.selected_attribute]
        })
          .then((res) => {
            console.log("item-specific-list", res);

            if (res.data.status == 0) {
              this.product_list = res.data.result.item_list
            }
          })
      } catch (err) {
        console.log("")
      }
    },

    filterSheet() {
      // first set all rows and cols visibility to false
      this.headers.rows.forEach(x => x.is_visible = false)
      this.headers.cols.forEach(x => x.is_visible = false)

      // then set the selected range to visible
      this.headers.rows.forEach(x => {
        if (parseFloat(x.value) >= parseFloat(this.range.spherical.min) && parseFloat(x.value) <= parseFloat(this.range.spherical.max)) {
          x.is_visible = true
        }
      })
      this.headers.cols.forEach(x => {
        if (parseFloat(x.value) >= parseFloat(this.range.cylinder.min) && parseFloat(x.value) <= parseFloat(this.range.cylinder.max)) {
          x.is_visible = true
        }
      })
    }
  }
})
</script>