<template>
  <span v-if="userLoggedIn" class="no-padding">
    <v-row class="text-center no-padding" dense>
      <v-col cols="12" class="no-padding" dense>
        <v-text-field
          label="Name, barcode, shortcode"
          v-model="searchField"
          @keypress.enter="searchTheItem"
          @keypress="keypressSearchTheItem"
          hide-details="auto"
          :append-icon="icons.mdiMagnify"
          class="no-padding"
          @input="
            (v) => {
              searchField = v.toUpperCase();
            }
          "
          single-line
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding" v-if="itemList.length >= 0" dense>
      <v-col cols="12" class="no-padding" dense>
        <v-data-table
          dense
          :headers="headers"
          :items="itemList"
          hide-default-footer
          class="no-padding"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="addItem(item)">{{ icons.mdiCartPlus }}</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import Vue from "vue";
import { mdiCartPlus, mdiMagnify } from "@mdi/js";

import { itemSearch } from "../../services/apiCall.js";

export default Vue.extend({
  name: "ItemSeachAndAddComponent",

  props: ["isCounterRate"],

  data: () => ({
    searchField: "",
    icons: {
      mdiCartPlus,
      mdiMagnify,
    },
    headers: [
      { text: "Name", value: "item_name" },
      { text: "Qty", value: "quantity" },
      { text: "CR", value: "counter_price" },
      { text: "DR", value: "dispatch_price" },
      { text: "", value: "actions" },
    ],
    itemList: [],
    searchActive: false,
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    keypressSearchTheItem() {
      if (this.searchActive) return;

      if (this.searchField.trim() === "") return;
      if (this.searchField.length < 2) return;

      if (this.searchActive) return;

      this.searchActive = true;
      setTimeout(this.searchTheItem, 2000);
    },
    searchTheItem() {
      console.log("searchItem", this.searchField);
      if (this.searchField === "") {
        this.searchActive = false;
        return;
      }
      try {
        itemSearch({ search_filter: this.searchField })
          .then((res) => {
            // console.log("itemSearch", res);
            if (res.data.status === 0) {
              this.itemList = res.data.result.item_list;
              if (this.itemList.length === 1) {
                this.addItem(this.itemList[0]);
                this.searchField = "";
              }
              this.searchActive = false;
            } else {
              console.log("itemSearch - err", res);
            }
          })
          .catch((err) => {
            console.log("itemSearch - err", err);
            this.itemList = [];
            this.searchActive = false;
          });
      } catch (err) {
        console.log("searchTheItem - err", err);
        this.itemList = [];
        this.searchActive = false;
      }
    },
    addItem(item) {
      // console.log("addItem", item);
      var itemToAdd = {
        id: item.id,
        item_name: item.item_name,
        price: this.isCounterRate ? item.counter_price : item.dispatch_price,
        quantity: 1,
        discount: 0,
        tax_rate: item.tax_rate,
        hsn_code: item.tax_rate.hsn_code,
      };
      this.$store.dispatch("addToCart", itemToAdd);
    },
  },
});
</script>