/* eslint-disable */ 

import Vue from 'vue';
import Vuex from 'vuex';

const DB:any = require("../services/db");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userLoggedIn: false,
    user: {},
    billingCustomer: {},
    customers: new Array<any>(),
    cart: new Array<any>(),
    branches: new Array<any>(),
    categories: new Array<any>(),
    salesOrder: {},
  },
  mutations: {
    setUserLoggedIn(state, userLoggedIn) {
      state.userLoggedIn = userLoggedIn;
    },
    setUser(state, user) {
      state.user = user;
      DB.putUser('user', user);
    },
    setBranches(state, branches) {
      state.branches = branches;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    addCustomer(state, customer) {
      state.customers.unshift(customer);
    },
    setBillingCustomer(state, customer) {
      state.billingCustomer = customer;
    },
    setSalesOrder(state, salesOrder) {
      state.salesOrder = salesOrder;
    },
    setCart(state, newCart) {
      state.cart.splice(0, state.cart.length);
      for(let idx=0; idx<newCart.length; idx++) {
        state.cart.unshift(newCart[idx]);
      }
    },
    addToCart(state, item) {
      const idx = state.cart.findIndex((x) => x.id === item.id);
      if (idx < 0) {
        state.cart.unshift(item);
      } else {
        state.cart[idx].quantity = parseFloat(state.cart[idx].quantity) + parseFloat(item.quantity);
        state.cart[idx].price = item.price;
      }
    },
    deleteFromCart(state, item) {
      const idx = state.cart.findIndex((x) => x.id === item.id);
      if (idx < 0) {
        return;
      } else {
        if (state.cart[idx].quantity <= 1) {
          state.cart.splice(idx, 1);
        } else {
          state.cart[idx].quantity--;
        }
      }
    },
    purgeFromCart(state, item) {
      const idx = state.cart.findIndex((x) => x.id === item.id);
      if (idx < 0) {
        return;
      } else {
        state.cart.splice(idx, 1);
      }
    },
    purgeCart(state) {
      state.cart = [];
    },
  },
  actions: {
    userLoggedIn(context, userLoggedIn) {
      context.commit('setUserLoggedIn', userLoggedIn);
    },
    user(context, user) {
      context.commit('setUser', user);
    },
    setBranches(context, branches) {
      context.commit('setBranches', branches);
    },
    setCategories(context, categories) {
      context.commit('setCategories', categories);
    },  
    addCustomer(context, customer) {
      context.commit('addCustomer', customer);
    },
    setBillingCustomer(context, customer) {
      context.commit('setBillingCustomer', customer);
    },
    setSalesOrder(context, salesOrder) {
      context.commit('setSalesOrder', salesOrder);
    },
    addToCart(context, item) {
      context.commit('addToCart', item);
    },
    deleteFromCart(context, item) {
      context.commit('deleteFromCart', item);
    },
    purgeFromCart(context, item) {
      context.commit('purgeFromCart', item);
    },
    purgeCart(context) {
      context.commit('purgeCart');
    },  
    setCart(context, newCart) {
      context.commit('setCart', newCart);
    },
  },
  getters: {
    userLoggedIn(state) {
      return state.userLoggedIn;
    },
    user(state) {
      return state.user;
    },

    customers(state) {
      return state.customers;
    },

    billingCustomer(state) {
      return state.billingCustomer;
    },

    cart(state) {
      return state.cart;
    },

    branches(state) {
      return state.branches;
    },

    categories(state) {
      return state.categories;
    },

    salesOrder(state) {
      return state.salesOrder;
    },
  }
});
