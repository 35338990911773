<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        {{appName()}} for {{clientName()}}
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://sudarsanscientific.com"
        target="_blank"
        text
      >
        <span class="mr-2">{{appVersion()}}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

      <v-btn v-if="userLoggedIn" text @click="logOut">
        <span class="mr-2">Logout</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <LoginComponent/>
      <DashboardComponent/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import LoginComponent from './components/main/LoginComponent.vue';
import DashboardComponent from './components/main/DashboardComponent.vue';
import { closeDB, clearObjectStore } from './services/db.js';
import { appVersion, appName, clientName } from './services/utils.js';

export default Vue.extend({
  name: 'App',

  components: {
    LoginComponent,
    DashboardComponent,
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },
  data: () => ({
    appVersion,
    appName,
    clientName,
    closeDB,
    clearObjectStore,
  }),
  methods: {
    async logOut() {
      console.log("logOut");
      this.$store.dispatch('userLoggedIn', false);

      await this.clearObjectStore();
      await this.closeDB();
    }
  }
});

</script>
