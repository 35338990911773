<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn raised color="success" @click="printBarCode" rounded>
          Print
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div id="barcodePreview">
          <table width="100%" style="border: 0px; border-spacing: 5px;">
            <tbody>
              <tr v-for="(row, ridx) in paperProperties.data" v-bind:key="ridx">
                <td v-for="(col, cidx) in row" v-bind:key="cidx" style="text-align: center; margin-bottom: 20px;">
                  <small style="text-align: center;">{{ barcodeObj.itemName }}</small>
                  <VueBarcode 
                    v-bind:value="col"
                    width="2"
                    height="60"
                    font-size="10"
                    text-margin="10"
                    format="CODE128"
                  > 
                    NA 
                  </VueBarcode>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import Vue from "vue";

import VueBarcode from "vue-barcode";

import { printPage } from "../../services/print.js";

export default Vue.extend({
  name: "BarcodeLabelComponent",

  props: ["barcode", "itemName", "itemCode", "paperType"],

  components: {
    VueBarcode,
  },

  data: () => ({
    barcodeObj: {
      barcode: "",
      itemName: "",
      itemCode: "",
      paperType: "",
    },
    paperProperties: {
      colsPerRow: 0,
      rowsPerCol: 0,
      data: [],
    },
  }),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.barcodeObj.barcode = this.barcode;
      this.barcodeObj.itemName = this.itemName;
      this.barcodeObj.itemCode = this.itemCode;
      this.barcodeObj.paperType = this.paperType;

      if (this.barcodeObj.paperType == "A4") {
        this.paperProperties.colsPerRow = 2;
        this.paperProperties.rowsPerCol = 8;

        this.genPaperData();
      } else {
        // default to only only print
        this.paperProperties.colsPerRow = 1;
        this.paperProperties.rowsPerCol = 1;

        this.genPaperData();
      }
    },

    genPaperData() {
      this.paperProperties.data = [];
      let barcode = this.barcodeObj.barcode;
      for (let idx = 0; idx < this.paperProperties.rowsPerCol; idx++) {
        this.paperProperties.data.push([]);
        for (let jdx = 0; jdx < this.paperProperties.colsPerRow; jdx++) {
          this.paperProperties.data[idx].push(barcode);
        }
      }
    },

    async printBarCode() {
      console.log("printBarCode");

      let pres = await printPage(
        this.barcodeObj.itemCode,
        document.querySelector("#barcodePreview")
      );
      console.log("[print-barcode]", pres);
    },
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },
});
</script>