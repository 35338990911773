/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** general utilities */

export function appName() {
    return "Optikals";
}

export function clientName() {
    return "Sudarsan Scientific"
}

export function appVersion() {
    return "v0.1.12042024";
}