<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-right">
      <v-col cols="12">
        <v-btn
          raised
          color="success"
          @click="showOpticalSheet"
          v-if="!this.showOpticalSheetClicked"
          rounded
          >Sheet</v-btn
        >
        <v-btn
          depressed
          color="error"
          @click="showOpticalSheet"
          v-if="this.showOpticalSheetClicked"
          rounded
          >Cancel</v-btn
        >
        &nbsp;&nbsp;
        <v-btn
          raised
          color="success"
          @click="addItem"
          v-if="!this.addItemClicked"
          rounded
          >Add Item</v-btn
        >
        <v-btn
          depressed
          color="error"
          @click="addItem"
          v-if="this.addItemClicked"
          rounded
          >Cancel</v-btn
        >
        &nbsp;&nbsp;
        <v-btn
          raised
          color="success"
          @click="stockTransfer"
          v-if="!this.stockTransferClicked"
          rounded
          >Transfers</v-btn
        >
        <v-btn
          depressed
          color="error"
          @click="stockTransfer"
          v-if="this.stockTransferClicked"
          rounded
          >Cancel</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="!addItemClicked && !stockTransferClicked && !showOpticalSheetClicked">
        <v-col cols="12">
            <item-list-component></item-list-component>
        </v-col>
    </v-row>
    <v-row v-if="addItemClicked && !stockTransferClicked && !showOpticalSheetClicked">
      <v-col cols="12">
        <add-item-component></add-item-component>
      </v-col>
    </v-row>
    <v-row v-if="!addItemClicked && stockTransferClicked && !showOpticalSheetClicked">
      <v-col cols="12">
        <stock-transfer-component></stock-transfer-component>
      </v-col>
    </v-row>
    <v-row v-if="!addItemClicked && !stockTransferClicked && showOpticalSheetClicked">
      <v-col cols="12">
        <optical-sheet-component></optical-sheet-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import AddItemComponent from "../stocks/AddItemComponent.vue";
import ItemListComponent from "../stocks/ItemListComponent.vue";
import StockTransferComponent from "../stocks/StockTransferComponent.vue";
import OpticalSheetComponent from "../common/OpticalSheetComponent.vue";

export default Vue.extend({
  name: "StocksComponent",

  components: {
    AddItemComponent,
    ItemListComponent,
    StockTransferComponent,
    OpticalSheetComponent,
},

  data: () => ({
    addItemClicked: false,
    stockTransferClicked: false,
    showOpticalSheetClicked: false,
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    addItem() {
      console.log("addItem");
      this.addItemClicked = !this.addItemClicked;
      if (this.addItemClicked) {
        this.stockTransferClicked = false;
        this.showOpticalSheetClicked = false;
      }
    },
    searchItem() {
      console.log("searchItem", this.searchField);
      return true;
    },
    stockTransfer() {
      console.log("stockTransfer");
      this.stockTransferClicked = !this.stockTransferClicked;
      if (this.stockTransferClicked) {
        this.addItemClicked = false;
        this.showOpticalSheetClicked = false;
      }
    },
    showOpticalSheet() {
      console.log('showOpticalSheet')
      this.showOpticalSheetClicked = !this.showOpticalSheetClicked;
      if (this.showOpticalSheetClicked) {
        this.addItemClicked = false;
        this.stockTransferClicked = false;
      }
    },
  },
});
</script>