/* eslint-disable */

import { openDB } from 'idb';

var _items, _categories, _orders, _customers, _branches, _user;

var _db = openDB("optics", 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
        // …
        console.log("upgrade db", db, oldVersion, newVersion, transaction);

        _items = db.createObjectStore('items');
        _categories = db.createObjectStore('categories');
        _customers = db.createObjectStore('customers');
        _orders = db.createObjectStore('orders');
        _branches = db.createObjectStore('branches');
        _user = db.createObjectStore('user');
    },
    blocked() {
        // …
    },
    blocking() {
        // …
    },
    terminated() {
        // …
    },
});

export async function clearObjectStore() {
    // clear the object stores 
    var tx = (await _db).transaction((await _db).objectStoreNames, "readwrite");

    _items = tx.objectStore('items');
    _categories = tx.objectStore('categories');
    _customers = tx.objectStore('customers');
    _orders = tx.objectStore('orders');
    _branches = tx.objectStore('branches');
    _user = tx.objectStore('user');

    _items.clear();
    _categories.clear();
    _customers.clear();
    _orders.clear();
    _branches.clear();
    _user.clear();
}

export async function closeDB() {
    (await _db).close();
}

export async function initDB() {
    if (_db) {
        await closeDB();
    }

    _db = openDB("optics", 1, {
        upgrade(db, oldVersion, newVersion, transaction) {
            // …
            console.log("upgrade db", db, oldVersion, newVersion, transaction);

            _items = db.createObjectStore('items');
            _categories = db.createObjectStore('categories');
            _customers = db.createObjectStore('customers');
            _orders = db.createObjectStore('orders');
            _branches = db.createObjectStore('branches');
            _user = db.createObjectStore('user');
        },
        blocked() {
            // …
        },
        blocking() {
            // …
        },
        terminated() {
            // …
        },
    });

    await _db;
    await clearObjectStore();
    return;
}

export async function getUser(key) {
    return await (await _db).get('user', key);
}

export async function putUser(key, value) {
    // console.log('putuser', key, value);
    return (await _db).put('user', value, key);
}
