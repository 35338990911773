<template>
  <v-container v-if="!userLoggedIn">
    <v-row>
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-img class="mx-auto" src="@/assets/logo.png" width="150"> </v-img>
          </v-col>
        </v-row>
        <h3>Sudarsan Scientific Company</h3><br />
        <v-form ref="loginForm" @submit.prevent="logMeIn" v-model="valid" lazy-validation>
          <v-row>
            <v-col offset-md="4" cols-md="4" md="4">
              <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col offset-md="4" cols-md="4" md="4">
              <v-text-field v-model="password" :rules="passwordRules" type="password" label="Password" required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col offset-md="4" cols-md="4" md="4">
              <v-autocomplete v-model="selectedBranch" :items="branches" :rules="[(v) => !!v || 'Select a branch']"
                label="Branch" required></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col offset-md="4" cols-md="4" md="4">
              <v-btn :disabled="!valid || submitDisabled" color="success" @click="logMeIn" raised rounded>
                Sign In
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import { branches, login } from "../../services/apiCall.js";
import { initDB } from "../../services/db.js";
import AlertComponent from "../common/AlertComponent";

export default Vue.extend({
  components: { AlertComponent },
  name: "LoginComponent",

  data: () => ({
    initDB,
    valid: false,
    submitDisabled: false,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    selectedBranch: "ERN",
    branches: [],
    alert: {
      error: false,
      success: false,
      message: "",
    },
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    branchList() {
      return this.$store.getters.branches;
    },
  },

  created: function () {
    branches({})
      .then((res) => {
        // console.log("branches", res, this);
        if (res.data.status === 0) {
          this.saveBranches(res);
        } else {
          this.alert.error = true;
          this.alert.success = false;
          this.alert.message = "Unable to connect to server. Error: " + res.data.error.error_message;
        }
      })
      .catch((err) => {
        console.log("branches - err", err);
        this.alert.error = true;
        this.alert.success = false;
        this.alert.message = "Unable to connect to server. Error: " + err;
      });
  },

  methods: {
    saveBranches(res) {
      var branchList = res.data.result.branch_list;
      this.$store.dispatch("setBranches", branchList);
      this.branches = branchList.map((x) => x.branch_code);
    },
    async logMeIn() {
      console.log("logMeIn ... ");
      this.submitDisabled = true;
      this.alert.error = false;

      if (this.$refs.loginForm.validate()) {
        try {
          login({
            user_email: this.email,
            password: this.password,
            branch_code: this.selectedBranch,
          })
            .then(async (res) => {
              console.log("login", res);

              if (res.data.status === 0) {
                // init the DB
                try {
                  await this.initDB();
                } catch (err) {
                  console.log("initDB - err", err);
                }
                // TODO:
                this.$store.dispatch("user", res.data.result);
                this.$store.dispatch("userLoggedIn", true);
                this.submitDisabled = false;
              } else {
                console.log("login - err", res);
                this.submitDisabled = false;
                this.alert.error = true;
                this.alert.message =
                  "Error logging in: " + res.data.error.error_message;
              }
            })
            .catch((err) => {
              console.log("login - err", err);
              this.submitDisabled = false;
              this.alert.error = true;
              this.alert.message = "Error logging in: " + err;
            });
        } catch (err) {
          console.log("login - err", err);
          this.submitDisabled = false;
          this.alert.error = true;
          this.alert.message = "Error logging in: " + err;
        }
      }
    },
  },
});
</script>
