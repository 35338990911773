<template>
  <v-container v-if="userLoggedIn" class="px-0" fluid>
    <v-row v-if="alert.error || alert.success">
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12"> Returns </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12" class="no-padding">
        <v-autocomplete
          v-model="gstOrderNumber"
          :items="gstOrderNumberList"
          label="Select Order Number to return"
          hide-no-data
          hide-details
          required
          @change="orderChanged"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" class="no-padding" v-if="orderToReturn.items && orderToReturn.items.length > 0">
        <div id="billPreview">
          <table width="100%" style="border: 0px; border-spacing: 0px">
            <!-- customer contact, Bill number -->
            <tbody>
              <tr>
                <td
                  style="
                    border: 1px solid;
                    border-right: 0px;
                    margin-right: 0px;
                    text-align: left;
                    padding-left: 10px;
                  "
                  colspan="8"
                >
                  Bill No: <b>{{ orderToReturn.order_id }}</b
                  ><br />
                  Name: <b>{{ orderToReturn.party.party_name }}</b
                  ><br />
                  Address: {{ orderToReturn.party.party_city }},
                  {{ orderToReturn.party.party_address }}
                </td>
                <td
                  style="
                    border: 1px solid;
                    border-left: 0px;
                    margin-left: 0px;
                    text-align: left;
                  "
                  colspan="4"
                >
                  Date: <b>{{ new Date(orderToReturn.createdAt).toDateString() }}</b> <br />
                  GSTIN: {{ orderToReturn.party.party_gstin }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="no-padding text-left"
        v-if="orderToReturn.items && orderToReturn.items.length > 0"
      >
        <v-row>
          <v-col col="8"> Item Name </v-col>
          <v-col col="2"> Qty </v-col>
          <v-col col="2"> Rate </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="no-padding text-left"
        v-if="orderToReturn.items && orderToReturn.items.length > 0"
      >
        <v-row v-for="item in orderToReturn.items" v-bind:key="item.id">
          <v-col col="8"
            ><input
              type="checkbox"
              v-model="item.return_item"
              :value="item.return_item"
              :disabled="item.is_return"
            />&nbsp;{{ item.item_name }}</v-col
          >
          <v-col col="2">{{ item.quantity }}</v-col>
          <v-col col="2">{{ item.total_value }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="orderToReturn.items && orderToReturn.items.length > 0">
      <v-col size="12">
        <v-btn raised color="success" @click="acceptReturn" rounded>
          Accept Return
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import { listGSTOrderIDs, fetchGSTOrder, returnGSTOrder } from "../../services/apiCall.js";
import AlertComponent from "../common/AlertComponent";

export default Vue.extend({
  name: "ReturnBillComponent",

  components: {
    AlertComponent,
  },

  data: () => ({
    gstOrderNumber: "",
    gstOrderNumberList: [],
    alert: {
      error: false,
      success: false,
      message: "",
    },
    orderToReturn: {},
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
      let startDate = new Date();
      let endDate = new Date();
      
      listGSTOrderIDs({ start_date: startDate, end_date: endDate })
        .then((res) => {
          console.log("listGSTOrder", res);

          if (res.data.status === 0) {
            this.gstOrderNumberList = res.data.result.order_list.map(
              (x) => x.order_id
            );
          } else {
            console.log("listGSTOrder - err1", res);
          }
        })
        .catch((err) => {
          console.log("listGSTOrder - err2", err);
        });
    },
    acceptReturn() {
      console.log("acceptReturn");

      // generate a return order
      let returnOrder = {
        "order_id": this.orderToReturn.order_id, 
        "party_id": this.orderToReturn.party.id,
        "order_items": this.orderToReturn["items"]
      };

      console.log("return order - payload", returnOrder);

      returnGSTOrder(returnOrder).then((return_order) => {
        console.log("returnGSTOrder", return_order);
        if (return_order.data.status != 0) {
          this.alert.error = true;
          this.alert.success = false;
          this.alert.message = return_order.data.error.error_message;
          return;
        } 

        // probably suceeded ?
        this.alert.error = false;
        this.alert.success = true;
        this.alert.message = "Return successful.";
      }).catch((err) => {
        console.log("acceptReturn - returnGSTOrder - err", err);
      });
    },
    orderChanged(order_id) {
      console.log("orderChanged", order_id);

      // 1. Fetch Bill Details
      fetchGSTOrder({ order_id: order_id }).then((gst_order) => {
        console.log("fetchGSTOrder", gst_order);
        if (gst_order.data.status != 0) {
          this.alert.error = true;
          this.alert.success = false;
          this.alert.message = gst_order.data.error.error_message;
          return;
        }

        // 2. Is the Bill returnable ?
        gst_order = gst_order.data.result.gst_order;

        if (gst_order.is_return || gst_order.order_status != "Returned") {
          // 3. If yes, fetch the item details
          this.orderToReturn = gst_order;

          // 4. Are the items in the Bill returnable ?

          // 5. Show only the items that are returnable
        }
      }).catch((err) => {
        console.log("orderChanged - fetchGSTOrder - err", err);
      });
    },
  },
});
</script>
